import {mapMutations, mapActions, mapGetters} from "vuex";
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import {directive as onClickaway} from 'vue-clickaway';
import {dynamicHead} from "../../mixins/dynamic-head";
//sections


export default {
    name: "video-gallery",
    components: {
        VueSlickCarousel
    },
    mixins: [dynamicHead],
    directives: {
        onClickaway: onClickaway,
    },
    data() {
        return {
            busy: false,
            crumbs: [
                {src: 'home', title: this.$t('home.title'), slug: '/'},
                {src: 'Галерея', title: this.$t('gallery.title'), slug: 'Галерея'}
            ],
            playButton: true,
            isPlayVideo: false,
            videoId: '',
            videosCollection: [],
            modalIsOpen: false,
            videoSlider: {
                "infinite": true,
                "slidesToShow": 1,
                "slidesToScroll": 1,
                arrows: false,
            }
        }

    },
    mounted() {
    },
    created() {
        this.getBannerContent('galleries').then(() => {
            this.setPageItem(this.pageContent)
            document.title = `${this.pageContent.title}`
        })
        this.getPageContent()
        this.setRequestFlag(true);
    },
    computed: {
        ...mapGetters({
            videos: 'setting/videoGallery',
            pageContent: 'setting/pageContent',
            globalRequestsFlag: 'system/globalRequestsFlag'
        })
    },

    methods: {
        ...mapActions({
            getPageContent: 'setting/GET_VIDEO_GALLERY',
            getBannerContent: 'setting/GET_PAGE_CONTENT',
        }),
        ...mapMutations({
            setRequestFlag: 'system/SET_REQUESTS_FLAG',
            moreVideos: 'setting/SET_VIDEOS_PAGE'
        }),
        loadMore() {
            this.busy = true;
            this.moreVideos()
            this.getPageContent()
            this.busy = false;
        },
        openModal(item) {
            this.modalIsOpen = !this.modalIsOpen
            this.videosCollection = []
            this.videosCollection = item.images.data

            this.videosCollection = this.videosCollection.filter(el => el.id !== item.id)
            this.videosCollection.unshift(item)
            if (this.modalIsOpen) {
                document.getElementById('html').classList.add('hide')
            } else {
                document.getElementById('html').classList.remove('hide')
            }
        },
        playVideo(item) {
            // this.videosCollection = []
            this.playButton = false
            this.isPlayVideo = !this.isPlayVideo
            this.videoId = item.id
        },
        closeModal() {
            this.playButton = true
            this.videosCollection = []
            this.modalIsOpen = !this.modalIsOpen
            document.getElementById('html').classList.remove('hide')
        },
        showNext() {
            this.$refs.galleryCarousel.next()
        },
        showPrev() {
            this.$refs.galleryCarousel.prev()
        },
        away: function () {
            this.playButton = true
            this.videosCollection = []
            this.modalIsOpen = !this.modalIsOpen
            document.getElementById('html').classList.remove('hide')
        },

    }
}
